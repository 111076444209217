export const PERMISSION_ADMIN_INFO = "admin_info";
export const PERMISSION_AGENDA_BLOCKER = "AgendaBlocked";
export const PERMISSION_AGENDA_DISACTIVE = "Agendadesactivitésdu";
export const PERMISSION_AGENDA_CIMENTRY = "Agendaducimetière";
// export const xxx = "Agendaremised'urne,d";
// export const xxx = "Agendaremised'urneet";
// export const xxx = "AllowRadioBtn";
// export const xxx = "AlwGnrtDvsOneInv";
// export const xxx = "Attestation";
// export const xxx = "AuditLogs";
// export const xxx = "BlockAgendaTime";
// export const xxx = "BlockEntreprise";
// export const xxx = "BlockingPage";
// export const xxx = "Cafet_by_dec";
// export const xxx = "caicli";
// export const xxx = "CatForf";
// export const xxx = "Certificatdecrématio";
// export const xxx = "certificatderemisedu";
// export const xxx = "clockingTimes";
// export const xxx = "clotCaisse";
// export const xxx = "clotCaisseCaf";
// export const xxx = "Cloture";
// export const xxx = "Cloture_avant_14";
// export const xxx = "concess";
// export const xxx = "ConfigPackage";
// export const xxx = "CreateDuplicateDec";
// export const xxx = "CreateMorticWithVat";
// export const xxx = "CremaHeaderFooter";
// export const xxx = "CremCertificate";
// export const xxx = "CremWithoutFamily";
// export const xxx = "CrmNbPast";
// export const xxx = "Dashboard";
// export const xxx = "DayHall";
// export const xxx = "DaySticker";
// export const xxx = "DeceasedWithoutResa";
// export const xxx = "delfact";
// export const xxx = "DelPayGrp";
// export const xxx = "DispAuthorization";
// export const xxx = "Dispersion";
// export const xxx = "DispersionsList";
// export const xxx = "DispMemorial";
// export const xxx = "Dispo";
// export const xxx = "DocumentsDefunts";
// export const xxx = "Droitaêtreinhumé";
// export const xxx = "Droitàêtreinhumerurn";
// export const xxx = "DropDownTranslation";
// export const xxx = "DynCtrls";
// export const xxx = "DynStatReport";
// export const xxx = "EditCremationNumber";
// export const xxx = "EditEntAnytime";
// export const xxx = "EditNameMInOne";
// export const xxx = "EditNewBookings";
// export const xxx = "EntrClientNumber";
// export const xxx = "entreprise_signboard";
// export const xxx = "EpfCoo";
// export const xxx = "Estimate";
// export const xxx = "Fact";
// export const xxx = "FactArtAj";
// export const xxx = "FactArtPri";
// export const xxx = "FactCafet";
// export const xxx = "FactCafetEmployee";
// export const xxx = "FactIncin";
// export const xxx = "FactResMod";
// export const xxx = "FactResourceConfig";
// export const xxx = "FactSalle";
// export const xxx = "FactSupprimer";
// export const xxx = "Facturation";
// export const xxx = "FactureConfiguration";
// export const xxx = "finmois";
// export const xxx = "FinMoisStat";
// export const xxx = "ForceLiveStreaming";
// export const xxx = "Forfait";
// export const xxx = "ForfaitCmd";
// export const xxx = "ForfaitPc";
// export const xxx = "ForfaitPx";
// export const xxx = "ForfaitVer";
// export const xxx = "GesAcces";
// export const xxx = "GesCompta";
// export const xxx = "GesDispo";
// export const xxx = "GesEntr";
// export const xxx = "GesForf";
// export const xxx = "GesNumCrema";
// export const xxx = "GesOngl";
// export const xxx = "GesParam";
// export const xxx = "GesReserv";
// export const xxx = "GestDevis";
// export const xxx = "GestDocs";
// export const xxx = "GestEmplConcess";
// export const xxx = "GestInterface";
// export const xxx = "gestparc";
// export const xxx = "GestReport";
// export const xxx = "GestReportTemplate";
// export const xxx = "GesUrnes";
// export const xxx = "GesUrnesList";
// export const xxx = "GesUrnesParams";
// export const xxx = "GesWinb";
// export const xxx = "GoToPageBooking";
// export const xxx = "ImpDocDefunts";
// export const xxx = "IncinAtt";
// export const xxx = "IncinDupl";
// export const xxx = "info";
// export const xxx = "InfoSheet";
// export const xxx = "InterfaceAdvanced";
// export const xxx = "InterfaceClassic";
// export const xxx = "LabelTranslation";
// export const xxx = "LinkParEnt";
// export const xxx = "LiveStreaming";
// export const xxx = "LocGeoDeceased";
// export const xxx = "lockReservation";
// export const xxx = "locsalle";
// export const xxx = "LoginAs";
// export const xxx = "ManageComptaAccounts";
// export const xxx = "ManageComptaEnclose";
// export const xxx = "ManageComptaExport";
// export const xxx = "ManageComptaHist";
// export const xxx = "ManageDeletePayments";
// export const xxx = "ManageDoc";
// export const xxx = "ManageInvoiceCredit";
// export const xxx = "ManageInvoices";
// export const xxx = "manageLimitCredit";
// export const xxx = "ManageOverflows";
// export const xxx = "ManageRebill";
// export const xxx = "MesDevis";
// export const xxx = "MesFactures";
// export const xxx = "ModCloture";
// export const xxx = "modfact";
// export const xxx = "ModFactExp";
// export const xxx = "modification";
// export const xxx = "modification_user";
// export const xxx = "ModNumFact";
// export const xxx = "NCCA";
// export const xxx = "NewAgendas";
// export const xxx = "newsMessage";
// export const xxx = "notc";
// export const xxx = "NoteC";
// export const xxx = "OldInciArchives";
// export const xxx = "onglAdmin";
// export const xxx = "onglAgenda";
// export const xxx = "onglCafet";
// export const xxx = "onglRapp";
// export const xxx = "onglRest";
// export const xxx = "OrnamentsDelivery";
// export const xxx = "ornement";
// export const xxx = "ParamAgenda";
// export const xxx = "ParamCrematoriums";
// export const xxx = "ParamGen";
// export const xxx = "Params";
// export const xxx = "ParamSalle";
// export const xxx = "PartnerSites";
// export const xxx = "PFEnvelop";
// export const xxx = "PrmColors";
// export const xxx = "Profil";
// export const xxx = "ProgressAgenda";
// export const xxx = "Province";
// export const xxx = "rap_salle";
// export const xxx = "rap_salle_salle";
// export const xxx = "rapann";
// export const xxx = "RapAnnStat";
// export const xxx = "RapAttestation";
// export const xxx = "RapCaf";
// export const xxx = "RapCerem";
// export const xxx = "RapCrema";
// export const xxx = "RapDemCaf";
// export const xxx = "RapEtiqu_cadr";
// export const xxx = "RapEtiqu_nocadr";
// export const xxx = "RapEtiqu_urne";
// export const xxx = "rapfac";
// export const xxx = "rapfaccli";
// export const xxx = "rapfactregistre";
// export const xxx = "RapIncin";
// export const xxx = "RapIncinSemaine";
// export const xxx = "RapInst";
// export const xxx = "RapOblation";
// export const xxx = "rappaym";
// export const xxx = "RapPlaq";
// export const xxx = "Rapport_cafet";
// export const xxx = "RapportPf";
// export const xxx = "rapregistre";
// export const xxx = "Rapsalle";
// export const xxx = "RdvSemainePf";
// export const xxx = "ReBilCrArt";
// export const xxx = "Récépissédecrémation";
// export const xxx = "RechEntr";
// export const xxx = "Recurrence";
// export const xxx = "RegistreDispersion";
// export const xxx = "RegistreMemo";
// export const xxx = "ReportConfig";
// export const xxx = "ReportConfiguration";
// export const xxx = "reporting";
// export const xxx = "ResaModalDocuments";
// export const xxx = "ResCafet";
// export const xxx = "ResCafetAjout";
// export const xxx = "ResCafetDep";
// export const xxx = "ResCafetEff";
// export const xxx = "ResCafetModif";
// export const xxx = "ResCeremModPart";
// export const xxx = "ResCertifPdf";
// export const xxx = "ResConcessAjout";
// export const xxx = "ResConcessEff";
// export const xxx = "ReserType";
// export const xxx = "ReservationModal";
// export const xxx = "ResFactTop";
// export const xxx = "ResInciDelMinOne";
// export const xxx = "ResInciMoveMinOne";
// export const xxx = "ResIncin";
// export const xxx = "ResIncinAjout";
// export const xxx = "ResIncinAjout_lev2";
// export const xxx = "ResIncinDep";
// export const xxx = "ResIncinDisp";
// export const xxx = "ResIncinEff";
// export const xxx = "ResIncinModif";
// export const xxx = "ResIncinModPart";
// export const xxx = "ResIncinOld";
// export const xxx = "ResIncinPdf";
// export const xxx = "ResIncinPermis";
// export const xxx = "ResIncinPermisJour";
// export const xxx = "ResInfoManquentes";
// export const xxx = "ResPlaquAjout";
// export const xxx = "ResSalle";
// export const xxx = "ResSalleAjout";
// export const xxx = "ResSalleDep";
// export const xxx = "ResSalleEff";
// export const xxx = "ResSalleModif";
// export const xxx = "ResStock";
// export const xxx = "Sepa";
// export const xxx = "SepaGuichet";
// export const xxx = "StatsParameters";
// export const xxx = "StockdeCellulesréfri";
// export const xxx = "suivi_remarque";
// export const xxx = "suivi_suppr";
// export const xxx = "suivi_tout";
// export const xxx = "SuiviCercueil";
// export const xxx = "Summary";
// export const xxx = "SuppFact";
// export const xxx = "SupplementAFacturer";
// export const xxx = "Supplementfact";
// export const xxx = "SynchroCaisse";
// export const xxx = "SynchroCli";
// export const xxx = "SynchroForf";
// export const xxx = "SyntheseCAClient";
// export const xxx = "Template";
// export const xxx = "TemplateProfile";
// export const xxx = "UpdateIdCremaInResa";
// export const xxx = "UploadDoc";
// export const xxx = "UrnCertif";
// export const xxx = "UrnCertifContact";
// export const xxx = "UrnCertifPF";
// export const xxx = "UrnCertifProcuration";
// export const xxx = "UrnDeliveryDoc";
// export const xxx = "UrnDeposit";
// export const xxx = "User";
// export const xxx = "VideoRecord";
// export const xxx = "Ville";
// export const xxx = "visu_info";
// export const xxx = "WinbMod";
// export const xxx = "winImp";
// export const xxx = "winImpCaf";
// export const xxx = "winImpGlob";
// export const xxx = "winImpinc";
// export const xxx = "xmlaudio";