import { combineReducers } from 'redux'
function createId() {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const baseReducers = (reducers) => {
    const initialState = {
        _id: createId()
    }
    const identity = (state = initialState, action) => {
        return state;
    }
    return combineReducers({
        identity,
        ...reducers
    })
}
let actions = [];
export const SharedActions = (action) => {
    if (action && actions.indexOf(action) < 0) {
        actions = [...actions, action]
    }
    return actions;
}

let isLoad = false;
let actionInProgress = [];
export const boradcastReduxMiddleware = store => next => action => {
    try {
        const state = next(action);
        if (isLoad === false) {
            window.addEventListener('BROADCAST_STATE', function ({ detail }) {
                const currentState = store.getState();
                // console.log(`Receive Broad Cast  ${detail.action.type}`)
                if (detail?._id && currentState.identity?._id !== detail?._id
                    && actionInProgress.indexOf(detail.action) === -1) {
                    console.log(`Update State ${detail.action.type}`)
                    actionInProgress.push(detail.action);
                    store.dispatch(detail.action);
                    setTimeout(() => {
                        actionInProgress = actionInProgress.filter(n => n !== detail.action);
                    }, 500);
                }
            });
        }
        if (SharedActions().indexOf(action.type) >= 0 && actionInProgress.indexOf(action) === -1) {
            const _state = store.getState();
            const event = new CustomEvent('BROADCAST_STATE', { detail: { action, _id: _state.identity?._id } });
            window.dispatchEvent(event);
        }

        return state;
    } catch (err) {
        console.error('Caught an exception!', err)
    }
}