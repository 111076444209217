import { settingConfiguration } from '../configurations';
import { RESERVATION_SERVER } from '../constans';
import { HttpClient } from '../utils';

export const SearchDeceased = (params, callback) => {
    const name = params.merriedName ?? params.deceasedName;
    const param = {
        "deceasedName": name,
        "deceasedFirstname": params.deceasedFirstname,
        "deathDate": params.deathDate,
        "cenderDate": params.cenderDate,
        "morticianId": params.morticianId,
        "postCode": params.postCode,
        "numberOfResults": params.numberOfResults,
        "dontHaveReservation": params.dontHaveReservation,
        "includeReservationEnd": params.includeReservationEnd,
        "searchGlobal": params.searchGlobal,
        "agendaId":params.agendaId,
        "roomId":params.roomId,
        "skip":params.skip
    }
    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.post(`${url}/api/Deceased/Search`, param, result => {
        if (callback) callback(result.data, result);
    });
};

export const GetDeceasedById = (deceasedId, callback) => {
    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.get(`${url}/api/Deceased?id=${deceasedId}`, result => {
        if (callback) callback(result.data, result);
    });
};