import React, { useState, useEffect } from 'react';
import { CURRENT_PERMISSIONS, TOKEN } from '../../constans';
import { settingConfiguration } from '../../configurations';
import { TextInput } from '../../components';
import CButton from '../../components/Button/CButton';
import { GetProfile, Login } from '../../services'
import { HttpClient } from '../../utils';

export const LoginContainer = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setLogin] = useState(false);
    const bc = new BroadcastChannel('auth-channel');
    const onValidate = () => {
        Login(userName, password, (result) => {
            if (result?.data?.access_token) {
                const accessToken = result?.data?.access_token;
                settingConfiguration.set(TOKEN, accessToken)
                setLogin(accessToken !== undefined);
                bc.postMessage({ login: accessToken !== undefined })
                GetProfile(e => {
                    settingConfiguration.set(CURRENT_PERMISSIONS, e.data)
                });
            }
        });
    }
    useEffect(() => {
        let accessToken = settingConfiguration.get(TOKEN);
        setLogin(accessToken !== undefined);
    }, [])
    const onChange = (e) => {
        setPassword(e.target.value)
    }
    const onChangeUserName = (e) => {
        setUserName(e.target.value)
    }
    const onLogout = () => {
        settingConfiguration.clear(TOKEN);
        setLogin(false)
    }
    const onCheckApi = () => {
        HttpClient.get('https://localhost:5003/api/Home', (e) => {

        });
    }
    bc.onmessage = (ev) => {
        if (ev.data.logout) {
            settingConfiguration.clear(TOKEN);
            setLogin(false)
        }

    }
    // if (isLogin)
    //     return <React.Fragment>
    //         <h1>You are logged in</h1>
    //         <CButton onClick={onLogout}>
    //             Logout
    //         </CButton>
    //         <CButton onClick={onCheckApi}>
    //             Check API
    //         </CButton>
    //     </React.Fragment>
    return (
        <div>
            Login : <TextInput onChange={onChangeUserName} value={userName} />
            Password : <TextInput type={'password'} onChange={onChange} value={password} />
            <CButton onClick={onValidate}>
                Login
            </CButton>
            {/* <CButton onClick={onCheckApi}>
                Check API
            </CButton> */}
        </div>
    );
};

export default LoginContainer;