import React, { useEffect, useState } from 'react';
import MicroFrontend from '../../components/Microfrontend';
import { settingConfiguration } from '../../configurations';
import { TOKEN } from '../../constans';
const style = {
    display: 'block',
    width: '100%'
}
export const PlaygroundContainer = () => {
    const [host, setHost] = useState("https://react-cre-deceased.net1-01.dev.voxteneo.com");
    const [fn, setFunction] = useState("Deceased");
    const [data, setData] = useState("{\"type\":\"deceased\",\"styles\":\"[]\",\"scripts\":\"[]\"}");


    const [dataItem, setDataItem] = useState({});
    const [render, setRender] = useState(true);
    const onRender = () => {
        setDataItem(JSON.parse(data));
        setRender(true);
    }
    useEffect(() => {
        setRender(false);
    }, [data, fn, host]);
    return (
        <div>
            <form
                name="advanced_search"
                className="ant-advanced-search-form"
            >
                <label>Host Address : </label>
                <input style={style} placeholder="Host" defaultValue={host} onChange={(e) => setHost(e.target.value)} />


                <label>Function Name for Render </label>
                <input style={style} placeholder="Function name" defaultValue={fn} onChange={(e) => setFunction(e.target.value)} />

                <label>Data </label>
                <textarea style={style} cols={4} placeholder="Data" defaultValue={data} onChange={(e) => setData(e.target.value)} />

                <span style={{ backgroundColor: 'green', padding: '8px', cursor: 'pointer' }} onClick={onRender}>
                    Render
                </span>
            </form>
            <br />
            {render && <MicroFrontend host={host} name={fn} data={{ ...dataItem, token: settingConfiguration.get(TOKEN) }} />}
        </div >
    );
};

export default PlaygroundContainer;