import React, { useEffect, useState } from 'react';
import { useCoreTranslation } from '../../i18n';
import SkeletonLoader from './Skeleton';
export const makeId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const MicroFrontend = (props) => {
    const [isLoading, setLoading] = useState(true)
    const [uniqueId, setUniqueId] = useState(undefined)
    const [, setError] = useState(false)

    useEffect(() => {
        setUniqueId(makeId(5));
    }, [])

    useEffect(() => {
        console.log(`MicrofrontEnd ${props.data.type} ${uniqueId}`)
    }, [props.data, uniqueId])

    const renderMicroFrontend = () => {
        if (!uniqueId) return;
        const { name, window } = props;
        console.log(`MicrofrontEnd  Prepare ${name} ${uniqueId} ${props.data.type}`)
        if (window[`render${name}`]) {
            const interval = setInterval(() => {
                try {
                    const control = document.getElementById(`${uniqueId}-container`);
                    if (!control) {
                        console.log(`MicrofrontEnd  ${uniqueId} Stop `)
                        clearInterval(interval);
                        return;
                    }
                    const innerHtml = control.innerHTML;
                    if (innerHtml?.length !== 58) {
                        clearInterval(interval);
                        setLoading(false);
                    }
                } catch (err) {
                    console.log(`MicrofrontEnd ${name}  Error ${err}`)
                    console.error(err);
                    setLoading(false);
                }

            }, 100);
            setTimeout(() => {
                console.log(`MicrofrontEnd ${name} Force IsLoading False after 10 seconds`)
                setLoading(false);
            }, 10000)
            window[`render${name}`](`${uniqueId}-container`, props.data);
            console.log(`MicrofrontEnd  Render ${name}`)
        } else {
            setTimeout(renderMicroFrontend, 5000);
        }
    };

    useEffect(() => {
        if (!uniqueId) return;
        const { name, host, document, data } = props;
        const scriptId = `micro-frontend-script-${name}`;
        console.log(`MicrofrontEnd  Loaded ${name} ${uniqueId} ${props.data.type}`)
        if (window.isDebug)
            console.log({ scriptId });
        if (data?.styles) {
            for (const key in data.styles) {
                if (data.styles.hasOwnProperty(key)) {
                    const style = data.styles[key];
                    if (document.head.innerHTML.indexOf(style) === -1) {
                        const link = document.createElement('link');
                        link.type = 'text/css';
                        link.rel = 'stylesheet';
                        link.href = style;
                        document.head.appendChild(link);
                    }
                }
            }
        }
        if (data?.scripts) {
            for (const key in data.scripts) {
                if (data.scripts.hasOwnProperty(key)) {
                    const script = data.scripts[key];
                    if (document.head.innerHTML.indexOf(script) === -1) {
                        const script = document.createElement('script');
                        script.id = scriptId;
                        script.crossOrigin = '';
                        script.src = script;
                        script.onload = renderMicroFrontend;
                        document.head.appendChild(script);
                    }
                }
            }
        }
        if (document.getElementById(scriptId)) {
            if (window[`unmount${name}`])
                window[`unmount${name}`](`${uniqueId}-container`);
            renderMicroFrontend();
            return;
        }
        fetch(`${host}/asset-manifest.json?v=${new Date().getTime()}`)
            .then(res => res.json())
            .then(manifest => {

                if (window.addScript) {
                    window.addScript(scriptId, `${host}${manifest['files']['main.js']}`, renderMicroFrontend)
                    return;
                }
                if (window.isDebug)
                    console.log({ manifest })
                const script = document.createElement('script');
                script.id = scriptId;
                script.type = "text/javascript";
                script.src = `${host}${manifest['files']['main.js']}`;
                if (window.isDebug)
                    console.log(`Set to cahche ${host}${manifest['files']['main.js']}`);
                script.onload = renderMicroFrontend;
                if (!document.getElementById(scriptId))
                    document.head.appendChild(script);
                else
                    setTimeout(renderMicroFrontend, 100)

            }).catch(() => {
                setLoading(false);
                setError(true);
            });
        return () => {
            if (window[`unmount${name}`])
                window[`unmount${name}`](`${uniqueId}-container`);
        }
    }, [props.host, uniqueId, props.data]);


    const LoadingIndicator = (props) => {
        switch (props.loaderType ?? "") {
            case "Skeleton":
                return <SkeletonLoader style={props.styleLoader} />
            default:
                return <div className="loading-wrapper" style={props.styleLoader}>
                    <LoadingBox />
                </div>
        }
    }
    return <React.Fragment>
        {isLoading && <LoadingIndicator {...props} />}
        {uniqueId && <main id={`${uniqueId}-container`} className={props?.className} />}
    </React.Fragment >;
}
export const LoadingBox = () => {
    const { t } = useCoreTranslation();
    return <div className="popup-loading">
        <div className="popup-loading-wrapper">
            <div className="popup-loading-image">
            </div>
            <div className="popup-loading-body">
                <p>{t('Chargement en cours, merci de patienter :)')}</p>
            </div>
        </div>
    </div>
}
MicroFrontend.defaultProps = {
    document,
    window,
};

export default MicroFrontend;

export * from './Skeleton'