import { settingConfiguration } from '../configurations';
import { CREMAGEST_V4_URL } from '../constans';
import { HttpClient } from '../utils';

export const PostAjaxHelper = (formData, callback) => {
    const url = settingConfiguration.get(CREMAGEST_V4_URL);
   
    HttpClient.post(`${url}/Content/AjaxHelper.aspx`, formData, result => {
        if (callback) callback(result.Data, result);
    });
}

export const GetAllHistories = (params, callback) => {
    const formData = new FormData();
    formData.append('function', 'GetAllHistoryDetails');
    formData.append('datas', JSON.stringify(params));
    PostAjaxHelper(formData, callback);
  };
export const GetDataForComboboxByFieldName = (fieldName, callback) => {
    const formData = new FormData();
    formData.append('function', 'GetDataForComboboxByFieldName');
    formData.append('fieldName', fieldName);

    PostAjaxHelper(formData, callback);
};
export const ExportHistoryReservation = (filter, callback) => {
    const formData = new FormData();
    formData.append('function', 'ExportHistoryReservation');
    formData.append('datas', filter);

    PostAjaxHelper(formData, callback);
};

export const GetPermisFileName = (params, callback) => {
    const formData = new FormData();
    formData.append('function', 'GetPermisFileName');
    formData.append('reservationId', JSON.stringify(params));
    PostAjaxHelper(formData, callback);
  };

  export const GetPermisDejourFileName = (params, callback) => {
    const formData = new FormData();
    formData.append('function', 'GetPermisDejourFileName');
    formData.append('reservationId', JSON.stringify(params));
    PostAjaxHelper(formData, callback);
  };

  export const GenerateReservationPdf = (reservationId,lg,crematoriumId,type, callback) => {
    const formData = new FormData();
    formData.append('function', 'GenerateReservationPdf');
    formData.append('reservationId', JSON.stringify(reservationId));
    formData.append('lg', JSON.stringify(lg));
    formData.append('crematoriumId', JSON.stringify(crematoriumId));
    formData.append('type', JSON.stringify(type));
    PostAjaxHelper(formData, callback);
  };
