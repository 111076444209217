import CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../../constans'
export default {
    Encrypt: (value) => {
        const key = CryptoJS.AES.encrypt(value, SECRET_KEY);
        return key.toString();
    },
    Decrypt: (value) => {
        try {
            const bytes = CryptoJS.AES.decrypt(value, SECRET_KEY)
            var originalText = bytes.toString(CryptoJS.enc.Utf8)
            return originalText;
        } catch {
            return value;
        }

    },
    UniqueKey: (value) => {
        return CryptoJS.SHA1(value).toString();
    },

}