import { settingConfiguration } from '../configurations';
import { MESSAGE_SERVER } from '../constans';
import { HttpClient } from '../utils';

export const GetMessage = (maxResult, skipCount, callback) => {
    HttpClient.get(`${settingConfiguration.get(MESSAGE_SERVER)}/api/Notification/GetNotifications?MaxResult=${maxResult}&SkipCount=${skipCount}`, result => {
        callback(result);
    });
};
export const MarkAsRead = (id, callback) => {
    HttpClient.post(`${settingConfiguration.get(MESSAGE_SERVER)}/api/Notification/MarkAsRead?id=${id}`, null, null, callback);
};