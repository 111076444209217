import { settingConfiguration } from '../configurations';
import { IDENTITY_SERVER, CLIENT_ID, CLIENT_SECRET, CONFIGURATION_SERVER } from '../constans';
import { HttpClient } from '../utils';
let clientId = "";
let clientSecret = "";
export const Login = (username, password, callback) => {
    const url = settingConfiguration.get(IDENTITY_SERVER)
    if (!clientId)
        clientId = settingConfiguration.get(CLIENT_ID)
    if (!clientSecret)
        clientSecret = settingConfiguration.get(CLIENT_SECRET)
    const parameter = new URLSearchParams();
    parameter.append('grant_type', 'password');
    parameter.append('client_id', clientId);
    parameter.append('client_secret', clientSecret);
    parameter.append('username', username);
    parameter.append('password', password);
    parameter.append('scope', 'configuration.api reservation.api');
    HttpClient.post(`${url}connect/token`, parameter, callback)
}

export const GetProfile = (callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER)
    HttpClient.get(`${url}/api/Profile/AccessList`, {}, callback)
}