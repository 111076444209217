import { settingConfiguration } from '../configurations';
import { AGENDA_SERVER, RESERVATION_SERVER, MESSAGE_SERVER } from '../constans';
import { HttpClient } from '../utils';
export const GetAgendaIncineration = (param, callback) => {
    const url = settingConfiguration.get(AGENDA_SERVER);
    HttpClient.get(`${url}/api/Reservation/GetReservationType`, {
        cache: true
    }, result => {
        if (callback) callback(result.data, result);
    });
};
export const GetAgendaRooms = (param, callback) => {
    const url = settingConfiguration.get(AGENDA_SERVER);
    HttpClient.get(`${url}/api/Reservation/GetReservationType`, {
        cache: true
    }, result => {
        if (callback) callback(result.data, result);
    });
};
export const AvailableTimeBooking = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/AvailableTimeBooking?agendaType=${params?.agendaType}&week=${params?.week}&year=${params?.year}`, {
        cache: true
    }, result => {
        callback(result);
    });
};
export const GetRoomHourByAgendaType = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/GetRoomHourByAgendaType?agendaType=${params?.agendaType}`, result => {
        callback(result);
    });
};
export const GetAgendaLegendColor = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetReservationType?takeAll=true`, result => {
        callback(result);
    });
};
export const GetNewAgendaInformations = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/GetNewAgendaInformations?agendaId=${params?.agendaId}&week=${params?.week}&year=${params?.year}`, {
        cache: true
    }, result => {
        if (callback) callback(result);
    });
};
export const GetAgendaReservationByAgendaId = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetAgendaReservationByAgendaId?agendaId=${params?.agendaId}&week=${params?.week}&year=${params?.year}`, {
        cache: true
    }, result => {
        if (callback) callback(result);
    });
};
export const GetUserEncrypt = callback => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/GetUserEncrypt`, result => {
        callback(result);
    });
};
export const GetRoomByAgendaType = (agendaType, callback) => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/GetRoomByAgendaType?agendaType=${agendaType}`, result => {
        callback(result.data, result);
    });
};
export const SetUrnMessageToNotif = callback => {
    HttpClient.get(`${settingConfiguration.get(MESSAGE_SERVER)}/api/Notification/SetUrnMessageToNotif`, result => {
        callback(result);
    });
};
export const GetAgendaType = callback => {
    HttpClient.get(`${settingConfiguration.get(AGENDA_SERVER)}/api/Agenda/GetAgendaType`, result => {
        callback(result);
    });
};