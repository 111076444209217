import { settingConfiguration } from "../configurations";

export const reducerWithLocalStorage = (name, state) => {
    return (_state, action) => {
        if (action.type.indexOf('@@') >= 0) {
            const item = settingConfiguration.get(name);
            if (item) {
                _state = item;
            }
        }
        const latestState = state(_state, action)
        settingConfiguration.set(name, latestState)
        return latestState;
    };
}