import axios from 'axios'
import _ from 'lodash'
import { settingConfiguration } from '../../configurations';
import { TOKEN, EXPIRED_REQUEST, UNAUTHORIZE_MESSAGE, INTERNAL_SERVER_ERROR, UNHANDLE_EXCEPTION, SEE_IN_LOG } from '../../constans';
import { ShowError } from '../../components/';
import i18n from '../../i18n'
import encrypte from '../encrypte';
axios.interceptors.request.use(async (config) => {
    if (!config.skipHeader) {
        let accessToken = settingConfiguration.get(TOKEN);
        if (!accessToken)
            accessToken = settingConfiguration.get("TOKEN")
        if (accessToken) {
            config.headers['Authorization'] = "Bearer " + accessToken;
            if (window.__cremagest) {
                if (document.cookie.indexOf(window.__cremagest.__id) === -1)
                    document.cookie += document.cookie + ";ASP.NET_SessionId=" + window.__cremagest.__id;
            }
            config['Content-Type'] = 'application/json';
        }
    }
    return config;
});
const errorHandle = (err, callback) => {
    if (callback)
        callback({ data: { success: false } });
    if (err?.response?.status === 401) {
        ShowError(i18n.t(UNAUTHORIZE_MESSAGE));
        return;
    }
    if (err?.response?.status === 500) {
        ShowError(i18n.t(INTERNAL_SERVER_ERROR));
        return;
    }
    if (err.response?.data?.error_description) {
        ShowError(i18n.t(err.response?.data?.error_description))
        return;
    }
    ShowError(i18n.t(UNHANDLE_EXCEPTION))


}
const eventBase = {};
const broadCastCallback = (key, events, result) => {
    for (const i in events) {
        if (Object.hasOwnProperty.call(events, i)) {
            const event = events[i];
            event(result.data, result);
        }
    }
    delete eventBase[key]
}
const removeStateRequest = (key) => {
    window.localStorage.removeItem(key);
}
const registerStateRequest = (key) => {
    eventBase[key] = [];
}
const registerCallback = (key, callback) => {
    const item = window.localStorage.getItem(key);
    return false;
}

const handleErrorResponse = (result) => {
    if (!result.status) {
        ShowError(result.response?.data?.error_description)
    } else {
        const { data } = result;
        if (data.success === false) {
            if (data.message)
                ShowError(`${data.code} - ${data.message}`)
            else
                ShowError(`${data.code} - ${SEE_IN_LOG}`)
        }
    }
}
const callbackResponse = (key, result, callback) => {
    removeStateRequest(key);
    handleErrorResponse(result);
    var events = eventBase[key] || [];
    broadCastCallback(key, events, result);
    const { data } = result;
    if (callback)
        callback(data, result);
}
const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
export const HttpClient = {
    post: (url, parameter, headers, callback, errCallback) => {
        if (!callback) {
            callback = headers;
            headers = undefined;
        }
        var key = JSON.stringify(url + parameter);
        if (registerCallback(key, callback)) return;
        registerStateRequest(key);
        window.localStorage.setItem(key, new Date());
        axios.post(url, parameter).then(result => {
            callbackResponse(key, result, callback);
        }, (e) => errorHandle(e, errCallback)).finally(() => {
            removeStateRequest(key);
        });
    },
    delete: (url, parameter, headers, callback,errCallback) => {
        if (!callback) {
            callback = headers;
            headers = undefined;
        }
        var key = JSON.stringify(url);
        if (registerCallback(key, callback)) return;
        registerStateRequest(key);
        window.localStorage.setItem(key, new Date());

        axios.delete(`${url}?${objectToQueryString(parameter)}`).then(result => {
            callbackResponse(key, result, callback);
        }, (e) => errorHandle(e, errCallback)).finally(() => {
            removeStateRequest(key);
        });;
    },
    get: (url, options = {}, callback,errCallback) => {
        if (typeof options === 'function')
            callback = options;
        var key = JSON.stringify(url);
        if (registerCallback(key, callback)) {
            return;
        }
        registerStateRequest(key);
        window.localStorage.setItem(key, new Date());
        let cache;
        const __key = encrypte.UniqueKey(key);
        if (options.cache) {
            cache = settingConfiguration.get(__key);
            if (cache) {
                callback(cache)
            }
        }
        axios.get(url).then(result => {
            if (options.cache) {
                cache = settingConfiguration.get(__key);
                if (cache && JSON.stringify(cache) === JSON.stringify(result.data)) {
                    return;
                }
                if (result.data)
                    settingConfiguration.set(__key, result.data)
            }
            callbackResponse(key, result, callback);

        }, (e) => errorHandle(e, errCallback)).finally(() => {
            removeStateRequest(key);
        });
    },
    put: (url, parameter, callback,errCallback) => {
        var key = JSON.stringify(url + parameter);
        if (registerCallback(key, callback)) return;
        registerStateRequest(key);
        window.localStorage.setItem(key, new Date());
        axios.put(url, parameter).then(result => {
            callbackResponse(key, result, callback);
        }, (e) => errorHandle(e, errCallback)).finally(() => {
            removeStateRequest(key);
        });;
    }
};


export default HttpClient