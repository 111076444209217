import React, { useState } from 'react';

export const TextInput = (props) => {
    const [value, setValue] = useState('');
    const onChange = (e) => {
        setValue(e.target.value);
        if (props.onChange)
            props.onChange(e)

    }
    return (
        <input type="text" {...props} value={value} onChange={onChange} />
    );
};

export default TextInput;