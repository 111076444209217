export const navigateUrl = (props, url) => {
    if (!url) return;

    if (window.parent && url.indexOf('http') >= 0 && url.indexOf(window.location.origin) === -1) {
        console.log(`Using Parent : ${url}`);
        window.parent.location = url;
    } else if (props?.history && (url.indexOf('http') === -1 || url.indexOf(window.location.origin) >= 0)) {
        console.log(`Using History: ${url}`);
        props.history.push(url.replace(window.location.origin, '').replace('//', '/'));
    } else {
        console.log(`Location : ${url}`);
        window.location = url;
    }
};