export const ShowAlert = (item) => {
    const event = new CustomEvent('ADD_MESSAGE', { detail: { message: item, type: "error" } });
    window.dispatchEvent(event);
}
export const ShowError = (item) => {
    const event = new CustomEvent('ADD_MESSAGE', { detail: { message: item, type: "error" } });
    window.dispatchEvent(event);
}

export const ShowSuccess = (item) => {
    const event = new CustomEvent('ADD_MESSAGE', { detail: { message: item, type: "success" } });
    window.dispatchEvent(event);
}
export const ShowProgress = (item) => {
    const event = new CustomEvent('ADD_MESSAGE', { detail: { message: item, type: "progress", permanent: true } });
    window.dispatchEvent(event);
}