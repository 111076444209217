export * from './axios'
export * from './component'
export * from './redux'
export const CREMAGEST_V4_URL = process.env.NODE_ENV !== 'development' ? 'LQwRWnVQig' : 'CREMAGEST_V4_URL';
export const TOKEN = process.env.NODE_ENV !== 'development' ? 'cRrISziJsc' : 'TOKEN';
export const CURRENT_PERMISSIONS = process.env.NODE_ENV !== 'development' ? 'dRNYDgAHxr' : 'CURRENT_PERMISSIONS';
export const IDENTITY_SERVER = process.env.NODE_ENV !== 'development' ? 'EeoeUuSeTN' : 'IDENTITY_SERVER';
export const CONFIGURATION_SERVER = process.env.NODE_ENV !== 'development' ? 'VqcBBBqSXu' : 'CONFIGURATION_SERVER';
export const CLIENT_ID = process.env.NODE_ENV !== 'development' ? 'SiKDDVzTkg' : 'CLIENT_ID';
export const CLIENT_SECRET = process.env.NODE_ENV !== 'development' ? 'ZDxJcAGCkg' : 'CLIENT_SECRET';
export const SECRET_KEY = process.env.NODE_ENV !== 'development' ? 'xlNNAOyfrq' : 'SECRET_KEY';
export const CURRENT_LANGUAGE = process.env.NODE_ENV !== 'development' ? 'xJSJOhaDaB' : 'CURRENT_LANGUAGE';
export const RESERVATION_SERVER = process.env.NODE_ENV !== 'development' ? 'YnwsukDuZk' : 'RESERVATION_SERVER';
export const BILLING_SERVER = process.env.NODE_ENV !== 'development' ? 'hJHXdKNIXB' : 'BILLING_SERVER';
export const MESSAGE_SERVER = process.env.NODE_ENV !== 'development' ? 'RaolZeaPpL' : 'MESSAGE_SERVER';


export const LOADING_STATE = "LOADING_STATE"

export const REDIRECT_URL = process.env.NODE_ENV !== 'development' ? 'jstZrVTzRy' : 'REDIRECT_URL';

export const EXPIRED_REQUEST = 30;

export const AGENDA_SERVER = process.env.NODE_ENV !== 'development' ? 'YnwsukDuZ2' : 'AGENDA_SERVER';
