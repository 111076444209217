import { settingConfiguration } from '../configurations';
import { RESERVATION_SERVER } from '../constans';
import { HttpClient } from '../utils';

export const GetAllDocuments = (docType,deceased,dateFrom,dateTo,description,callback) => {
  HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/GetAll?docType=${docType}&deceased=${deceased}&dateFrom=${dateFrom}&dateTo=${dateTo}&description=${description}`, result => {
    if (callback) callback(result.data, result);
  });
};

export const DeleteDocumentById = (id,callback) => {
  HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/Delete?documentId=${id}`, result => {
    if (callback) callback(result.data, result);
  });
};

export const CreateOrUpdateDocument = (data,callback) => {
  HttpClient.post(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/CreateOrUpdate`,data, (result) => {
    if (callback) callback(result.data, result);
  });
};

export const GetDocumentByReservationId = (reservationId,callback) => {
  HttpClient.post(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/Get?reservationId=${reservationId}`, (result) => {
    if (callback) callback(result.data, result);
  });
};

export const GetDocumentByDocId = (docId,callback) => {
  HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/GetByDocId?docId=${docId}`, (result) => {
    if (callback) callback(result.data, result);
  });
};

export const GetLabelDescription = (belong,callback) => {
  HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/GetFileDescription?belong=${belong}`, (result) => {
    if (callback) callback(result.data, result);
  });
};

export const UpdatePictureDeceasedDetail = (data,callback) => {
  HttpClient.post(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/UpdatePictureDeceasedDetail`,data, (result) => {
    if (callback) callback(result.data, result);
  });
};
