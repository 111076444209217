export const stringFormat = (message, data) => {
    if (typeof data === 'string')
        data = JSON.parse(data)
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            message = message.replace('{' + key + '}', element)
        }
    }
    return message;
}