
import Crypto from '../utils/encrypte'
export const settingConfiguration = {
    get: (name) => {
        let value;
        try {
            let result = undefined;

            if (window.Configuration) {
                if (window.localStorage.getItem(name)) {
                    value = window.localStorage.getItem(name)
                    if (process.env.NODE_ENV !== 'development')
                        value = Crypto.Decrypt(value);
                    result = JSON.parse(value);
                }
                if (window.Configuration[name])
                    result = window.Configuration[name];
            }
            if (window.isDebug)
                console.log(`get ${name} : ${result}`)
            return result;
        } catch {
            return value;
        }

    },
    set: (name, value) => {
        value = JSON.stringify(value);
        if (process.env.NODE_ENV !== 'development')
            window.localStorage.setItem(name, Crypto.Encrypt(value))
        else
            window.localStorage.setItem(name, (value))
    },
    clear: (name) => {
        window.localStorage.removeItem(name)
    }
};


