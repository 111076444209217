import { settingConfiguration } from '../configurations';
import { RESERVATION_SERVER, CREMAGEST_V4_URL, AGENDA_SERVER, CONFIGURATION_SERVER } from '../constans';
import { HttpClient } from '../utils';

export const SaveReservation = (data, callback, errCallback) => {
    HttpClient.post(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/SaveReservation`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }, (result) => { callback(result) }, errCallback)
};
export const GetReservationByDeceacedId = (deceasedId, reservationType, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/?deceasedId=${deceasedId}&reservationType=${reservationType}`, result => {
        callback(result);
    });
};
export const GetReservationById = (reservationId, reservationType, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/?reservationId=${reservationId}&reservationType=${reservationType}`, result => {
        callback(result);
    });
};
export const GetReservationByAgendaId = (params, callback) => {
    const { reservationId, reservationType, agendaItemId, agendaType } = params;
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/?reservationId=${reservationId}&reservationType=${reservationType}&agendaItemId=${agendaItemId}&agendaType=${agendaType}`, result => {
        callback(result);
    });
};
export const GetComponentReservationById = (reservationId, reservationType, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/?reservationId=${reservationId}&reservationType=${reservationType}`, result => {
        callback(result);
    });
};

export const GetDocumentByReservationId = (reservationId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/Get?reservationId=${reservationId}`, (result) => { callback(result) })
};

export const GetDocuments = (param, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/Get?reservationId=${param.reservationId}&guidReservation=${param.guidReservation}&belong=${param.belong}`, (result) => { callback(result) })
};

export const GetGuidForSession = (callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/GetGuidForSession/`, (result) => { callback(result) })
};

export const GetLabelDescription = (belong, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/GetLabelDescription?belong=${belong}`, (result) => { callback(result) })
};

export const DeleteDocumentById = (id, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/Delete?documentId=${id}`, result => {
        if (callback) callback(result.data, result);
    });
};

export const GetReservationByDateAndTypeForDropDownList = (reservationDate, reservationType, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/ByDateAndType?date=${reservationDate}&reservationType=${reservationType}`, (result) => { callback(result) })
};


export const GetDocumentByReservationPopupId = (reservationId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/ById/${reservationId}`, result => {
        callback(result);
    });
};

export const GenerateDocumentByReservationId = (params, callback) => {
    const param = {
        "ReservationId": parseInt(params.reservationId),
        "DocumentReports": params.documentReports,
        "SentEmail": params.sentEmail
    }
    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.post(`${url}/api/Document/GenerateDocument`, param, result => {
        if (callback) callback(result.data, result);
    });
};

export const UploadDocument = (params, callback) => {
    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.post(`${url}/api/Document/CreateOrUpdate`, params, result => {
        if (callback) callback(result.data, result);
    });
};

export const GetCompanyByIdAndType = (companyId, type, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Company/GetById?companyId=${companyId}&type=${type}`, result => {
        callback(result);
    });
};
export const GetPreviewDetail = (reservationId, agendaItemId, agendaType, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetPreviewDetail?ReservationId=${reservationId}&AgendaId=${agendaType}&AgendaItemId=${agendaItemId}`, (result) => { callback(result) })
};

export const GetReservationPermisFile = (reservationId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetPermisFile?reservationId=${reservationId}`, (result) => { callback(result) })
}

export const GetReservationPermisJourFile = (reservationId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetPermisJourFile?reservationId=${reservationId}`, (result) => { callback(result) })
}

export const GetReservationInternalDispersion = (reservationId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetInternalDispersion?reservationId=${reservationId}`, (result) => { callback(result) })
}


export const GetAllHistoriesByReservationId = (params, callback) => {
    const url = settingConfiguration.get(CREMAGEST_V4_URL);
    const formData = new FormData();
    formData.append('function', 'GetHistoryMessageByReservationId');
    formData.append('reservationId', params?.reservationId);
    formData.append('pageNumber', params?.pageNumber);
    formData.append('totalTake', params?.totalTake);

    HttpClient.post(`${url}/Content/AjaxHelper.aspx`, formData, result => {
        if (callback) callback(result.Data, result);
    });
};

export const DeleteReservation = (param, callback) => {
    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.post(`${url}/api/Reservation/DeleteReservation`, param, result => {
        if (callback) callback(result.data);
    });
};

export const GetAgendaReservations = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetAgendaReservations?agendaType=${params.agendaType}&week=${params.week}&year=${params.year}`, result => {
        if (callback) callback(result, result);
    });
};

export const GetDocumentReservationByIdReservationType = (id, type, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/ById/${id}?reservationType=${type}`, result => {
        callback(result);
    });
};
export const GetPrintDocumentBooking = (id, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Document/PrintDocumentBooking?agendaEntityId=${id}`, result => {
        callback(result);
    });
};

export const IsBlockingAvailable = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/IsBlockingAvailable?date=${params?.date}&time=${params?.time}&roomId=${params?.roomId}&duration=${params?.duration}&cancel=${params?.cancel}`, result => {
        callback(result);
    });
};

export const GetDefaultRoom = (params, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetDefaultRoom?stringHour=${params}`, result => {
        callback(result);
    });
};

export const GetServiceTypeByReservationType = (reservationTypeId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetServiceTypeByReservationType?reservationTypeId=${reservationTypeId}`, result => {
        callback(result);
    });
};

export const GetMaxDelayDate = (reservationDate, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/GetMaxDelayDate?reservationDate=${reservationDate}`, result => {
        callback(result);
    });
};

// export const GetListFacture = (reservationId, callback) => {
//     HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Billing/GetListFacture?reservationId=${reservationId}`, result => {
//         callback(result);
//     });
// };


export const GenerateFacture = (billIds, callback) => {
    const param = {
        "BillIds": billIds
    }

    const url = settingConfiguration.get(RESERVATION_SERVER);
    HttpClient.post(`${url}/api/Document/GenerateFacture`, param, result => {
        if (callback) callback(result);
    });
};

export const DeleteNewAgenda = (agendaRoomBookingId, comment,agendaId, callback) => {
    HttpClient.get(`${settingConfiguration.get(RESERVATION_SERVER)}/api/Reservation/DeleteNewAgenda?agendaRoomBookingItemId=${agendaRoomBookingId}&comment=${comment}&agendaId=${agendaId}`, result => {
        if (callback) callback(result);
    });
};


export const IsAvailabelDateReservation = (param, callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER);
    HttpClient.post(`${url}/api/AvailableDate/IsAvailabelDateReservation`, param, result => {
        if (callback) callback(result);
    });
};