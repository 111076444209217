

const getMonth = (currentDate) => {
    const month = (currentDate.getMonth() + 1);
    if (month < 10)
        return '0' + month;
    return month;
}
const getDate = (currentDate) => {
    const date = (currentDate.getDate());
    if (date < 10)
        return '0' + date;
    return date;
}
export const convertDoubletoTime = (value) => {
    if (!value)
        return '00:00'
    value = parseFloat(value)
    const time = value % 1 * 60
    const hour = value - (value % 1);
    return `${hour.toString().padStart(2, '0')}:${time.toString().padStart(2, '0')}`
}
export const convertTimeToDouble = (value) => {
    if (!value)
        return 0
    const arr = value.split(':');
    if (arr.length === 1)
        return parseFloat(arr[0])
    return parseFloat(arr[0]) + (parseFloat(arr[1]) / 60);
}
export function getMonday(d, addDays) {
    const date = new Date(d);
    var day = date.getDay(),
        diff = date.getDate() - day + (day == 0 ? -6 : 1) + (addDays ? addDays : 0); // adjust when day is sunday
    const result = new Date(date.setDate(diff));
    console.log(result)
    return result;
}
export const daysInfromation = (day) => {
    switch (day) {
        case 1: return "L";
        case 2: return "M";
        case 3: return "M";
        case 4: return "J";
        case 5: return "V";
        case 6: return "S";
        case 0: return "D";
    }
}
export function getWeek(date) {
    const currentdate = new Date(date);
    const oneJan = new Date(currentdate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    return result - 1;
}
export const formatDate = (date, format) => {


    if (!date) return null;
    let currentDate;
    if (typeof date === 'string')
        currentDate = new Date(date);
    else if (typeof date === 'number') {
        if (format === 'FULL_DATE')
            currentDate = new Date(date * 1000 );
        else
            currentDate = new Date(date * 1000 - (7 * 60 * 60 * 1000));
    }
    else
        currentDate = date;
    if (!format)
        return currentDate;

    // return Moment(currentDate).locale('fr').format('dddd D MMMM YYYY [à] hh:mm');

    if (format === 'HH:MM')
        return currentDate.getUTCHours().toString().padStart(2, '0') + ":" + currentDate.getUTCHours().toString().padStart(2, '0');
    if (format === 'DD.MM')
        return currentDate.getDate().toString().padStart(2, '0') + "." + (currentDate.getMonth() + 1).toString().padStart(2, '0');
    if (format === 'DD.MM.YYYY')
        return currentDate.getDate().toString().padStart(2, '0') + "." + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "." + currentDate.getFullYear();
    if (format === 'SHORT_DATE')
        return getDate(currentDate) + "." + (getMonth(currentDate)) + "." + currentDate.getFullYear();
    if (format === 'dddd D MMMM YYYY [à] hh:mm')
        return currentDate.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false });
    if (format === 'InversedDateFormat') {
        return getDate(currentDate) + "/" + (getMonth(currentDate)) + "/" + currentDate.getFullYear();
    }
    if (format === 'DATE')
        return currentDate.getFullYear() + "-" + getMonth(currentDate) + "-" + getDate(currentDate);
    if (format === 'dd/mm/yyyy HH:MM')
        return getDate(currentDate) + "/" + (getMonth(currentDate)) + "/" + currentDate.getFullYear() + " " + String(currentDate.getUTCHours()).padStart(2, '0') + ":" + String(currentDate.getUTCMinutes()).padStart(2, '0');

    return currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0') + " " + String(currentDate.getUTCHours()).padStart(2, '0') + ":" + String(currentDate.getUTCMinutes()).padStart(2, '0');
};

export const addHours = (date, hours) => {
    var dt = date;
        dt.setHours(dt.getHours() + hours);
        dt.setMinutes(dt.getMinutes() + 25);
        return dt;
}