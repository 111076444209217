import React, { Component } from 'react';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // log error messages to an error reporting service here
        console.error('Component Error', error, errorInfo)

    }

    render() {
        if (this.state.errorInfo) {
            return <React.Fragment></React.Fragment>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
