import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next'
import { settingConfiguration } from '../configurations';
import { CONFIGURATION_SERVER, CURRENT_LANGUAGE } from '../constans';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const lang = settingConfiguration.get(CURRENT_LANGUAGE)
const server = settingConfiguration.get(CONFIGURATION_SERVER)
i18next
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ['en', 'fr'],
        lng: lang || 'fr',
        debug: true,
        backend: {
            loadPath: `${server}/api/locales/{{lng}}/{{ns}}`
        }
        // resources: {
        //     en: {
        //         translation: en
        //     },
        //     fr: {
        //         translation: fr
        //     }
        // }
    });
export const i18n = i18next;
export const changeLanguage = (languange) => {
    settingConfiguration.set(CURRENT_LANGUAGE, languange)
}

export const useCoreTranslation = (module) => {
    const { t, i18n } = useTranslation(module)
    return {
        t: (key) => {
            module = module ?? 'translation';
            if (i18n.store?.data && i18n.store?.data[i18n.language])
                return i18n.store.data[i18n.language][module][key] ?? key;
            return t(key) ?? key;
        },
        i18n: i18n
    }
}
export default i18n