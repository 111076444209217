import { settingConfiguration } from '../configurations';
import { IDENTITY_SERVER, CLIENT_ID, CLIENT_SECRET, CONFIGURATION_SERVER } from '../constans';
import { HttpClient } from '../utils';
const generateParameters = (controlGroup) => {
    let parameters = "";
    for (const key in controlGroup) {
        if (Object.hasOwnProperty.call(controlGroup, key)) {
            const element = controlGroup[key];
            parameters += `&${key}=${element}`
        }
    }
    return parameters;
}
export const GetDataForComboboxByFormSettingId = (formId, controlGroup, callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER);
    const params = typeof (controlGroup) === 'function' ? '' : generateParameters(controlGroup);
    HttpClient.get(`${url}/api/Parameter/GetDataForComboboxByFormSettingId?formSettingId=${formId}${params}`, {
        cache: true
    }, result => {
        if (result.success) {
            const {
                data
            } = result;
            if (typeof (controlGroup) === 'function')
                controlGroup(data, result)
            else
                callback(data, result);
        }
    });
};
export const GetAllFormSetting = (controlGroup,reservationTypeId = 0, callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER);
    HttpClient.get(`${url}/api/Parameter/GetAllFormSetting?controlGroup=${controlGroup}&reservationTypeid=${reservationTypeId}`, {
        cache: true
    }, result => {
        if (callback) callback(result.data, result);
    });
};
export const GetCityList = (nameCountry = 'BE', cityName, cityId, take = 10, callback) => {
    let param
    const url = settingConfiguration.get(CONFIGURATION_SERVER);

    // cityId comes from formData and could be CIID or city name
    // prioritize finding by cityName because it means user is finding cities
    if (cityName) {
        param = `cityName=${cityName}`;
    } else if (/^[0-9]*$/.test(cityId)) {
        param = `cityId=${cityId}`;
    } else {
        if (cityId) {
            const splitData = cityId.split(' - ')[0];
            if (/^-?[\d.]+(?:e-?\d+)?$/.test(splitData)) {
                const changeToNumber = parseInt(splitData);
                if (changeToNumber < 0) {
                    param = `cityId=`;
                } else {
                    param = `cityId=${changeToNumber}`;
                }
            } else {
                param = `cityName=${splitData}`;
            }
        } else {
            param = `cityName=${cityName}`;
        }
    }
    HttpClient.get(`${url}/api/Parameter/GetListCity?countryCode=${nameCountry}&${param}&take=${take}`, {
        cache: false
    }, result => {
        if (callback) callback(result.data, result);
    });
};
export const GetCountryList = (nameCountry, take = 10, callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER);
    HttpClient.get(`${url}/api/Parameter/GetCountries?nameCountry=${nameCountry}&take=${take}`, {
        cache: false
    }, result => {
        ///api/Parameter/GGetCountries?countryName=${value}
        if (callback) callback(result.data, result);
    });
};
export const GetParameters = (type, value, language, crematoriumId, callback) => {
    const url = settingConfiguration.get(CONFIGURATION_SERVER);
    HttpClient.get(`${url}/api/Parameter/GetParameter?type=${type}&value=${value}&language=${language}&crematoriumId=${crematoriumId}`, {
        cache: true
    }, result => {
        if (callback) callback(result.data, result);
    });
};